import { gql } from '@apollo/client';

export const UPDATE_GROUP_STATUS = gql`
  mutation UpdateGroupStatus($id: ID!, $active: Boolean!) {
    updateGroupStatus(input: { id: $id, active: $active }) {
      group {
        id
        active
      }
      errors
    }
  }
`;

export const SEARCH_GROUPS = gql`
  query SearchGroups($query: String!) {
    searchGroups(query: $query) {
      id
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups($page: Int, $perPage: Int, $search: String) {
    groups(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          description
          createdAt
          updatedAt
          active
        }
      }
    }
  }
`;


export const CREATE_GROUP = gql`
  mutation CreateGroup($name: String!, $description: String) {
    createGroup(input: { name: $name, description: $description }) {
      group {
        id
        name
        description
        active
      }
      errors
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: ID!, $name: String!, $description: String, $active: Boolean!) {
    updateGroup(input: {id: $id, name: $name, description: $description, active: $active}) {
      group {
        id
        name
        description
        active
      }
      errors
    }
  }
`;