import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Row, Col, Card, Divider, Switch, Image, Checkbox } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ACCOUNT, UPDATE_ACCOUNT, GET_PLUGCHAT_STATUS, DISCONNECT_PLUGCHAT } from '../mutations/account';
import AddressForm from '../components/AddressForm';

const AccountSettings = () => {
  const [loadAccount, { loading: queryLoading, data }] = useLazyQuery(GET_ACCOUNT);
  const [storeAccount, { loading: mutationLoading }] = useMutation(UPDATE_ACCOUNT);
  const [loadPlugchatStatus, { data: plugchatData }] = useLazyQuery(GET_PLUGCHAT_STATUS, { fetchPolicy: 'network-only' });
  const [disconnectPlugchat] = useMutation(DISCONNECT_PLUGCHAT);


  const [qrCodeImage, setQrCodeImage] = useState('');
  const [plugChatConnected, setPlugChatConnected] = useState(false);
  const [connectedNumber, setConnectedNumber] = useState('');


  const [form] = Form.useForm();

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!plugChatConnected) {
        loadPlugchatStatus().then((value) => {
          let plugchatData = value.data

          setPlugChatConnected(plugchatData?.plugchatStatus?.plugchatConnected)
          if (plugchatData && plugchatData?.plugchatStatus?.plugchatConnected === false) {
            setQrCodeImage(plugchatData?.plugchatStatus?.plugchatQrCode);
          }
        });
      }
    }, 20000); // 20 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
  }, [plugChatConnected, loadPlugchatStatus]);

  useEffect(() => {
    if (plugchatData) {
      setPlugChatConnected(plugchatData?.plugchatStatus?.plugchatConnected);
      setConnectedNumber(plugchatData?.plugchatStatus?.connectedNumber);
      if (!plugchatData?.plugchatStatus?.plugchatConnected) {
        setQrCodeImage(plugchatData?.plugchatStatus?.plugchatQrCode);

      }
    }
  }, [plugchatData]);

  const handleDisconnect = async () => {
    disconnectPlugchat().then((data) => {
      message.success('Desconectado com sucesso');
      setPlugChatConnected(false);
      setQrCodeImage('');
      setConnectedNumber('');
    }).catch((error) => {
      message.error('Falha ao desconectar');
      console.error(error);
    });

  };

  useEffect(() => {
    if (data) {
      setQrCodeImage(data?.account?.plugchatQrCode)
      setPlugChatConnected(data?.account?.plugchatConnected)
      setConnectedNumber(data?.account?.connectedNumber);
      let cityData = {}
      if (data.account.address) {
        cityData = { value: data?.account?.address?.city?.id, label: `${data?.account?.address?.city?.name} - ${data?.account?.address?.city?.state?.initials}` }
      }
      form.setFieldsValue({
        ...data.account,
        address: {
          ...data.account.address,
          cityId: cityData
        }
      });
    }
  }, [data, form]);

  const handleUpdate = async (values) => {
    const addressValues = {
      ...values.address,
      cityId: values.address.cityId.value,
    };

    const { plugchatConnected, plugchatQrCode, ...filteredValues } = values;
    const finalValues = {
      ...filteredValues,
      address: addressValues,
    };
    try {
      const { data } = await storeAccount({
        variables: { attributes: finalValues }
      });
      if (data.storeAccount.errors.length > 0) {
        message.error(data.storeAccount.errors.join(', '));
      } else {
        message.success('Conta atualizada com sucesso');
      }
    } catch (error) {
      message.error('Falha ao atualizar a conta');
      console.error(error);
    }
  };

  if (queryLoading) return <p>Carregando...</p>;

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Col span={24}>
        <Card>
          <h2>Dados da conta</h2>
          <Form
            form={form}
            initialValues={data?.account}
            onFinish={handleUpdate}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="corporateName" label="Nome da Empresa">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="name" label="Razão Social">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="document" label="Documento">
                  <Input />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="phone" label="Telefone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="domain" label="Dominio">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="initialCode" label="Código inicial">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="currentCode" label="Código atual do pedido">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Plugchat</Divider>
            <Row gutter={16}>

              <Col justify="center" align="middle">
                <Form.Item name="plugchatConnected" label="Conectado">
                  <Checkbox disabled={true} checked={plugChatConnected} />
                </Form.Item>
              </Col>

              {!plugChatConnected && (
                <Col>
                  <Form.Item name="plugchatQrCode" label="Plugchat QR Code">
                    <Image
                      preview={false}
                      width={200}
                      src={qrCodeImage}
                    />
                  </Form.Item>
                </Col>
              )}
              {plugChatConnected && (
                <>
                  <Col >
                    <Form.Item label="Número Conectado">
                      <Input value={connectedNumber} disabled />
                    </Form.Item>
                  </Col>
                  <Col >
                    <Form.Item style={{ textAlign: 'right', paddingTop: '30px' }}>
                      <Button type="primary" onClick={handleDisconnect}>
                        Desconectar
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="plugchatToken" label="Token do Plugchat">
                  <Input.TextArea allowClear={true} />
                </Form.Item>
              </Col>


            </Row>
            <Divider>Endereço</Divider>
            <AddressForm initialValues={data?.account?.address || {}} />
            <Divider>Configurações de Pesquisa</Divider>
            <Row gutter={16}>
              <Col justify="center" align="middle">
                <Form.Item name="searchLimit" label="Limite de resultados" >
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item name="searchPartial" label="Parcial" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col >
                <Form.Item name="anyWord" label="Por qualquer palavra" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Carrinho</Divider>
            <Row gutter={16} >
              <Col justify="center" align="middle">
                <Form.Item name="abandonedCartTime" label="Tempo para abandonar (em horas)">
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col justify="center" align="middle">
                <Form.Item name="attemptsLimit" label="Tentativas de notificação">
                  <Input style={{ width: '50px' }} />
                </Form.Item>
              </Col>
              <Col justify="center" align="middle">
                <Form.Item name="notifyOnBusinessHours" label="Notifica em horário comercial" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={mutationLoading} style={{ width: '200px' }}>
                Atualizar conta
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AccountSettings;