import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Switch, Modal } from 'antd';
import { format } from 'date-fns';
import { formatCurrency, formatPhoneNumber } from '../util';
import { useUpdateCustomerStatus, GET_CUSTOMERS, CREATE_CUSTOMER, UPDATE_CUSTOMER, useUpdateCustomerBotStatus } from '../mutations/customers';
import CustomerForm from '../components/customers/CustomerForm';
import UpdateStatusBotModal from '../components/customers/UpdateStatusBotModal'; // Novo import


const Customers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBotModalVisible, setIsBotModalVisible] = useState(false); // Novo estado para o modal de status do bot
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [updateCustomerStatus] = useUpdateCustomerStatus();
  const [updateCustomerBotStatus] = useUpdateCustomerBotStatus();
  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    update(cache, { data: { createCustomer } }) {
      const newCustomer = createCustomer.customer;
      const existingCustomers = cache.readQuery({
        query: GET_CUSTOMERS,
        variables: { page: currentPage, perPage: pageSize, search: searchQuery },
      });

      cache.writeQuery({
        query: GET_CUSTOMERS,
        variables: { page: currentPage, perPage: pageSize, search: searchQuery },
        data: {
          customers: {
            ...existingCustomers.customers,
            edges: [{ node: newCustomer }, ...existingCustomers.customers.edges],
            totalCount: existingCustomers.customers.totalCount + 1,
          },
        },
      });
    },
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const handleBotStatusUpdate = async (newBotStatus, disableDuration) => {
    try {
      await updateCustomerBotStatus({
        variables: { id: selectedCustomer.id, botStatus: newBotStatus, disableDuration },
        update: (cache, { data: { updateCustomerBotStatus } }) => {
          const { customer } = updateCustomerBotStatus;
          cache.modify({
            id: cache.identify(customer),
            fields: {
              botStatus() {
                return newBotStatus;
              },
              botDisabledUntil() {
                return customer.botDisabledUntil;
              },
              timeUntilReactivation() {
                return customer.timeUntilReactivation;
              },
            },
          });
        }
      });
      setIsBotModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const showBotStatusModal = (customer) => {
    setSelectedCustomer(customer);
    setIsBotModalVisible(true);
  };

  const handleCancelBotModal = () => {
    setIsBotModalVisible(false);
  };

  const [fetchCustomers, { loading, error, data }] = useLazyQuery(GET_CUSTOMERS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  useEffect(() => {
    fetchCustomers(); // Fetch customers when component mounts
  }, [fetchCustomers]);

  const showConfirmModal = (customer) => {
    setSelectedCustomer(customer);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedCustomer;
    try {
      await updateCustomerStatus({
        variables: { id, active: !active },
        update: (cache, { data: { updateCustomerStatus } }) => {
          const { customer } = updateCustomerStatus;
          cache.modify({
            id: cache.identify(customer),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1); // Reset page to 1 when search term changes
    fetchCustomers({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchCustomers({
      variables: {
        page: page,
        perPage: pageSize,
        search: searchQuery,
      },
    });
  };

  const handleCreateCustomer = () => {
    setIsEditMode(false);
    setSelectedCustomer({ active: true });
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedCustomer(null);
    setErrorMessage(null);
  };

  const cleanData = (values) => {
    return {
      ...values,
      emailsAttributes: values?.emailsAttributes?.map(email => ({
        email: email.email,
        description: email.description,
        active: email.active,
      })),
      addressesAttributes: values?.addressesAttributes?.map(address => ({
        id: address.id,
        zipcode: address.zipcode,
        cityId: address.cityId.value || address.cityId,
        description: address.description,
        district: address.district,
        street: address.street,
        number: address.number,
        complement: address.complement,
        main: address.main,
      })),
    };
  };



  const handleSave = async (values) => {
    setErrorMessage(null);
    values.botStatus = values.botStatus ? 'bot_active' : 'bot_disabled'
    
    if (isEditMode) {
      try {
        const updatedValues = cleanData(values)
        const response = await updateCustomer({
          variables: { id: selectedCustomer.id, attributes: updatedValues }
        });

        const errors = response.data?.updateCustomer?.errors;
        if (errors && errors.length > 0) {
          setErrorMessage(errors.join(', '));
        } else {
          fetchCustomers(); // Refetch customers to update the list
          handleFormCancel(); // Close the modal
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Ocorreu um erro inesperado.');
      }
    } else {
      try {
        const response = await createCustomer({
          variables: { attributes: values }
        });

        const errors = response.data?.createCustomer?.errors;
        if (errors && errors.length > 0) {
          setErrorMessage(errors.join(', '));
        } else {
          fetchCustomers(); // Refetch customers to update the list
          handleFormCancel(); // Close the modal
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Ocorreu um erro inesperado.');
      }
    }
  };


  const handleEditCustomer = (customer) => {
    setIsEditMode(true);
    setSelectedCustomer(customer)
    setIsFormModalVisible(true);
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status Bot',
      key: 'botStatus',
      align: 'center',
      render: (text, record) => (
        < >
          <Switch
            checked={record.botStatus === 'bot_active'}
            onChange={() => showBotStatusModal(record)}
          />
          {record.botStatus === 'bot_disabled' && (
            <div style={{ marginTop: 8, fontSize: '12px', color: 'red' }}>
              {record.timeUntilReactivation}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Código',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apelido',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => formatPhoneNumber(phone),
    },
    {
      title: 'WhatsApp',
      dataIndex: 'whatsapp',
      key: 'whatsapp',
      render: (whatsapp) => formatPhoneNumber(whatsapp),
    },
    {
      title: 'Endereços',
      dataIndex: 'addresses',
      key: 'addresses',
      render: (addresses) => (
        <div>
          {addresses.map((address, index) => (
            <div key={index}>
              {address.fullAddress}
              {index < addresses.length - 1 && <br />}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Inscrição estadual',
      dataIndex: 'stateRegistration',
      key: 'stateRegistration',
    },
    {
      title: 'Pedido Mínimo',
      dataIndex: 'minimumOrder',
      key: 'minimumOrder',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Crédito',
      dataIndex: 'credit',
      key: 'credit',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Observação',
      dataIndex: 'observation',
      key: 'observation',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditCustomer(record)}>Editar</Button>
      ),
    },
  ];

  const customers = data?.customers?.edges.map(edge => edge?.node) || [];
  const { totalCount } = data?.customers || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateCustomer}>
          Criar Cliente
        </Button>
      </div>
      <Table
        dataSource={customers}
        columns={columns}
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={handleTableChange}
      />
      <Modal
        title="Criar Cliente"
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        width={1200} // Ajuste da largura do modal
        getContainer={false}
      >
        <CustomerForm
          initialValues={selectedCustomer}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          isEditMode={isEditMode}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmação"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        getContainer={false}
      >
        <p>Tem certeza que deseja continuar?</p>
      </Modal>
      <UpdateStatusBotModal
        visible={isBotModalVisible}
        onCancel={handleCancelBotModal}
        onUpdate={handleBotStatusUpdate}
        botStatus={selectedCustomer?.botStatus}
      />
    </>
  );
};

export default Customers;
