import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ConfirmEmail = () => {
  const location = useLocation();
  const history = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users/confirm?token=${token}`);
        alert(response.data.message);
        history('/login');
      } catch (error) {
        alert(error.response.data.error);
      }
    };

    confirmEmail();
  }, [token, history]);

  return <p>Confirmando...</p>;
};

export default ConfirmEmail;