import { gql, useMutation } from '@apollo/client';

export const GET_CURRENT_SELLER = gql`
  query GetCurrentSeller {
    seller {
      id
      name
      email
      active
      whatsapp
      plugchatConnected
      createdAt
    }
  }
`;



export const UPDATE_SELLER = gql`
  mutation UpdateSeller($id: ID!, $attributes: SellerAttributes!) {
    updateSeller(input: { id: $id, attributes: $attributes }) {
      seller {
        id
        name
        email
        active
        whatsapp
        createdAt
      }
      errors
    }
  }
`;

export const useUpdateSeller = () => {
  return useMutation(UPDATE_SELLER);
};
