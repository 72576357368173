import { gql, useMutation } from '@apollo/client';

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: ID!) {
    product(id: $id) {
      id
      reference
      name
      brand
      group {
        id
        name
      }
      un
      value
      multiplier
      stock
      minimumValue
      description
      costValue
      active
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($page: Int, $perPage: Int, $search: String) {
    products(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          brand
          price
          createdAt
          description
          internalId
          reference
          group {
            id
            name
          }
          active
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      },
      totalCount

    }
  }
`;

const UPDATE_PRODUCT_STATUS = gql`
  mutation UpdateProductStatus($id: ID!, $active: Boolean!) {
    updateProductStatus(input: { id: $id, active: $active }) {
      product {
        id
        active
      }
      errors
    }
  }
`;

export const useUpdateProductStatus = () => {
  return useMutation(UPDATE_PRODUCT_STATUS);
};

const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $attributes: ProductAttributes!
  ) {
    createProduct(input: { attributes: $attributes }) {
      product {
        id
        reference
        name
        brand
        group {
          id
          name
        }
        un
        value
        multiplier
        stock
        minimumValue
        costValue
        description
      }
      errors
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $attributes: ProductAttributes!
  ) {
    updateProduct(input: { attributes: $attributes }) {
      product {
        id
        reference
        name
        brand
        group {
          id
          name
        }
        un
        value
        multiplier
        stock
        minimumValue
        costValue
      }
      errors
    }
  }
`;

export const useCreateProduct = () => {
  return useMutation(CREATE_PRODUCT);
};

export const useUpdateProduct = () => {
  return useMutation(UPDATE_PRODUCT);
};