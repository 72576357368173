import React, { useState } from 'react';
import { Form, Input, Button, message, Row, Col, Card, Image } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import  client  from '../apolloClient';
const apiUrl = process.env.REACT_APP_API_URL;

const LoginForm = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);
    await client.resetStore();
    try {
      const response = await axios.post(`${apiUrl}/api/v1/auth/sign_in`, {
        email: values.email,
        password: values.password,
      });

      const { headers } = response;
      localStorage.setItem('access-token', headers['access-token']);
      localStorage.setItem('client', headers['client']);
      localStorage.setItem('uid', headers['uid']);

      message.success('Login realizado com sucesso');
      if (onLogin) {
        
        onLogin();
      } else {
        navigate('/orders'); // Redireciona para /orders após login bem-sucedido
      }
    } catch (error) {
      message.error('Não foi possível acessar');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <Card>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Image src={'/logo192.png'} alt="Logo" preview={false} width={400} />
            <h2>Login</h2>
          </div>
          <Form onFinish={handleLogin}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor, preencha seu email!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, preencha sua senha!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Acessar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => navigate('/register')} block>
                Não possui conta? Registre-se
              </Button>

              <Button type="link" onClick={() => navigate('/request-password-reset')} block>
                Esqueceu a senha?
              </Button>

              <Button type="link" onClick={() => navigate('/resend-confirmation-email')} block>
                Não recebeu o e-mail de confirmação?
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginForm;