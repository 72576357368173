// src/components/ProductModalForm.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Col, Row, InputNumber, Select } from 'antd';
import { useCreateProduct, useUpdateProduct } from '../../mutations/products';
import { SEARCH_GROUPS } from '../../mutations/groups';
import { gql } from '@apollo/client';
import './CustomForm.css';
import CurrencyInput from '../CurrencyInput';
import { useLazyQuery } from '@apollo/client';
import useDebounce from '../../hooks/useDebounce';

const ProductModalForm = ({ visible, onCancel, initialValues, isEditMode, refetchProducts }) => {
  const [createProduct] = useCreateProduct();
  const [updateProduct] = useUpdateProduct();
  const [searchGroups, { data: groupsData }] = useLazyQuery(SEARCH_GROUPS);
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [form] = Form.useForm();

  useEffect(() => {
    if (debouncedSearchTerm.length > 2 || debouncedSearchTerm === '*') {
      searchGroups({ variables: { query: debouncedSearchTerm } });
    }
  }, [debouncedSearchTerm, searchGroups]);

  useEffect(() => {
    if (groupsData) {
      setGroups(groupsData.searchGroups);
    }
  }, [groupsData]);

  useEffect(() => {
    form.resetFields();
    if (initialValues && initialValues.group) {
      setGroups([{ id: initialValues.group.id, name: initialValues.group.name }]);
      form.setFieldsValue({
        ...initialValues,
        group: initialValues.group.id,
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, isEditMode, form]);

  const handleSearchGroup = (value) => {
    setSearchTerm(value);
  };

  const handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      value: parseFloat(values.value),
      multiplier: parseFloat(values.multiplier),
      groupId: values.group,
      stock: parseFloat(values.stock),
      minimumValue: parseFloat(values.minimumValue),
      costValue: parseFloat(values.costValue),
    };
    try {
      if (isEditMode) {
        await updateProduct({
          variables: {attributes: {id: initialValues.id, ...formattedValues}},
          update: (cache, { data: { updateProduct } }) => {
            const { product } = updateProduct;
            cache.modify({
              id: cache.identify(product),
              fields: {
                reference() { return product.reference; },
                name() { return product.name; },
                brand() { return product.brand; },
                group() { return product.group; },
                un() { return product.un; },
                value() { return product.value; },
                multiplier() { return product.multiplier; },
                stock() { return product.stock; },
                minimumValue() { return product.minimumValue; },
                costValue() { return product.costValue; },
                description() { return product.description; },
              }
            });
          }
        });
      } else {
        await createProduct({
          variables: {attributes: formattedValues},
          update: (cache, { data: { createProduct } }) => {
            const { product } = createProduct;
            cache.modify({
              fields: {
                products(existingProducts = { edges: [] }) {
                  const newProductRef = cache.writeFragment({
                    data: product,
                    fragment: gql`
                      fragment NewProduct on Product {
                        id
                        reference
                        name
                        brand
                        description
                        group {
                          id
                          name
                        }
                        un
                        value
                        multiplier
                        stock
                        minimumValue
                        costValue
                      }
                    `
                  });
                  return {
                    ...existingProducts,
                    edges: [...existingProducts.edges, { node: newProductRef }],
                  };
                }
              }
            });
          }
        });
      }
      refetchProducts(); // Refetch products to update the list
    } catch (error) {
      console.error(error);
    } finally {
      form.resetFields();
      onCancel();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  }

  return (
    <Modal
      title={isEditMode ? "Editar Produto" : "Criar Produto"}
      open={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      getContainer={false}
      width={1200} 
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        className="custom-form"
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="reference" label="Referência" rules={[{ required: true, message: 'Por favor insira a referência' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="brand" label="Marca" rules={[{ required: true, message: 'Por favor insira a marca' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="group" label="Grupo" rules={[{ required: true, message: 'Por favor, selecione o grupo' }]}>
              <Select
                showSearch
                placeholder="Pesquisar grupo"
                onSearch={handleSearchGroup}
                filterOption={false}
                options={groups.map(group => ({ value: group.id, label: group.name }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="un" label="UN" rules={[{ required: true, message: 'Por favor insira a unidade' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="value" label="Valor" rules={[{ required: true, message: 'Por favor insira o valor' }]}>
              <CurrencyInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="stock" label="Estoque" rules={[{ required: true, message: 'Por favor insira o estoque' }]}>
              <InputNumber min={0} precision={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="multiplier" label="Multiplicador" rules={[{ required: true, message: 'Por favor insira o multiplicador' }]}>
              <InputNumber min={0} precision={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="minimumValue" label="Valor Mínimo" rules={[{ required: true, message: 'Por favor insira o valor mínimo' }]}>
              <CurrencyInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="costValue" label="Custo" rules={[{ required: true, message: 'Por favor insira o custo' }]}>
              <CurrencyInput />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Descrição" >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel}>
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Atualizar" : "Criar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductModalForm;
