import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Switch, Divider, Select } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_CITIES } from '../../mutations/address';
import useDebounce from '../../hooks/useDebounce';
import { CloseCircleOutlined } from '@ant-design/icons';
import './CustomerForm.css';
import { GET_CUSTOMER_BY_ID } from '../../mutations/customers';

const { Option } = Select;

const CustomerForm = ({ initialValues = {}, onFinish, onCancel, errorMessage, isEditMode = false }) => {
  const [form] = Form.useForm();
  const [getCustomerById] = useLazyQuery(GET_CUSTOMER_BY_ID);
  const botStatus = Form.useWatch('botStatus', form);

  const initialEmails = initialValues.emails || [{ email: '', description: '', active: true }];
  const initialAddresses = initialValues.addresses || [{
    zipcode: '',
    cityId: '',
    description: '',
    district: '',
    street: '',
    number: '',
    complement: '',
    main: false,
  }];

  const [searchCities, { data: citiesData }] = useLazyQuery(SEARCH_CITIES);
  const [cities, setCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay


  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      searchCities({ variables: { query: debouncedSearchTerm } });
    }
  }, [debouncedSearchTerm, searchCities]);

  useEffect(() => {
    if (citiesData) {
      setCities(citiesData.searchCities);
    }
  }, [citiesData]);

  const handleSearchCity = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    form.resetFields();
    if (isEditMode) {
      getCustomerById({ variables: { id: initialValues.id } }).then((response) => {
        console.log(response.data.customer)
        const customer = response?.data?.customer;
        if (customer) {
          const botStatus = customer.botStatus === 'bot_active';
          const addressesAttributes = customer.addresses.map(address => ({
            ...address,
            cityId: { value: address?.city?.id, label: `${address?.city?.name} - ${address?.city?.state?.initials}` }
          }));
          const emailsAttributes = customer.emails.map(email => ({
            ...email,
          }));
          form.setFieldsValue({ ...customer, addressesAttributes, emailsAttributes, botStatus });
        } else {
          form.resetFields();
        }
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, isEditMode, form, getCustomerById]);



  useEffect(() => {
    if (initialValues.city) {
      setCities([{ id: initialValues.city.id, name: initialValues.city.name, state: initialValues.city.state }]);
    }
  }, [initialValues]);

  const handleCancel = async (values) => {
    form.resetFields();
    form.setFieldsValue({})
    if (onCancel) onCancel();
  }
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ ...initialValues, emails: initialEmails, addresses: initialAddresses }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Razão Social"
            rules={[{ required: true, message: 'Por favor, insira a razão social ou nome' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nickname" label="Nome Fantasia">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="document"
            label="Documento"
            rules={[{ required: true, message: 'Por favor, insira o documento' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="stateRegistration" label="Inscrição Estadual">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="phone"
            label="Telefone Principal"
            rules={[{ required: true, message: 'Por favor, insira o telefone principal' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="phoneExtra" label="Telefone Secundário">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="whatsapp" label="WhatsApp">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="observation" label="Observação">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" orientationMargin="0" plain>
        Emails
      </Divider>
      <Form.List name="emailsAttributes" initialValue={initialEmails}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row gutter={16} key={key}>
                <Col span={10}>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    key={[fieldKey, 'email']}
                    label="Email"
                    rules={[{ required: true, message: 'Por favor, insira o email' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    key={[fieldKey, 'description']}
                    label="Descrição"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'active']}
                    key={[fieldKey, 'active']}
                    valuePropName="checked"
                    label="Ativo"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  <Button
                    type="text"
                    className='remove-email'
                    icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                    onClick={() => remove(name)}
                  />
                </Col>

              </Row>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              Adicionar Email
            </Button>
          </>
        )}
      </Form.List>
      <Divider orientation="left" orientationMargin="0" plain>
        Endereços
      </Divider>
      <Form.List name="addressesAttributes" initialValue={initialAddresses}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{ marginBottom: 24 }}>
                <Row gutter={16}>
                  <Col span={22}>
                    <Row gutter={16}>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'zipcode']}
                          key={[key, 'zipcode']}
                          label="CEP"
                          rules={[{ required: true, message: 'Por favor, insira o CEP' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'cityId']}
                          key={[key, 'cityId']}
                          label="Cidade"
                          rules={[{ required: true, message: 'Por favor, selecione a cidade' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Pesquisar cidade"
                            onSearch={handleSearchCity}
                            filterOption={false}
                            options={cities.map(city => ({ value: city.id, label: `${city.name} - ${city?.state?.initials}` }))}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'district']}
                          key={[key, 'district']}
                          label="Bairro"
                          rules={[{ required: true, message: 'Por favor, insira o bairro' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>

                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'street']}
                          key={[key, 'street']}
                          label="Rua"
                          rules={[{ required: true, message: 'Por favor, insira a rua' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                          key={[key, 'number']}
                          label="Número"
                          rules={[{ required: true, message: 'Por favor, insira o número' }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          key={[key, 'description']}
                          label="Descrição"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          {...restField}
                          name={[name, 'main']}
                          key={[key, 'main']}
                          label="Principal"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, 'complement']}
                          key={[key, 'complement']}
                          label="Complemento"
                        >
                          <Input.TextArea allowClear={true} />
                        </Form.Item>
                      </Col>

                    </Row>
                  </Col>
                  <Col style={{ textAlign: 'center', marginTop: '90px' }} span={2}>
                    <Button
                      type="text"
                      className='remove-email'
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>


              </div>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              Adicionar Endereço
            </Button>
          </>
        )}
      </Form.List>

      <Divider>Status do bot para o cliente</Divider>
      <Row gutter={16} key={'statusBotRow'}>
        <Col span={2}>
          <Form.Item
            name={'botStatus'}
            key={'botStatus'}
            valuePropName="checked"
            label="Ativo"
          >
            <Switch
            />
          </Form.Item>
        </Col>
        {!botStatus &&
          <>
            <Col span={4}>
              <Form.Item
                name={'disableDuration'}
                key={'disableDuration'}
                label="Duração"
              >
                <Select
                  // onChange={handleDisableDurationChange}
                  style={{ width: '100%' }}
                >
                  <Option value="3600">Por 1 hora</Option>
                  <Option value="28800">Por 8 horas</Option>
                  <Option value="86400">Por 24 horas</Option>
                  <Option value="forever">Para sempre</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              {initialValues.botStatus === 'bot_disabled' && (
                <div style={{ marginTop: 35, fontSize: '12px', color: 'red' }}>
                  {initialValues.timeUntilReactivation}
                </div>
              )}
            </Col>
          </>
        }

      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button onClick={handleCancel} style={{ marginRight: 8, backgroundColor: 'red', color: 'white' }}>
          Cancelar
        </Button>
        <Button type="primary" htmlType="submit">
          {isEditMode ? "Atualizar" : "Criar"}
        </Button>
      </div>
    </Form>
  );
};

export default CustomerForm;
