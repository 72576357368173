import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Row, Col, Card } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CURRENT_SELLER, UPDATE_SELLER } from '../mutations/sellers';

const SellerSettings = () => {
  const [loadSeller, { loading: queryLoading, data }] = useLazyQuery(GET_CURRENT_SELLER);
  const [updateSeller] = useMutation(UPDATE_SELLER);
  const [plugchatConnected, setPlugchatConnected] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {
    loadSeller();
  }, [loadSeller]);

  useEffect(() => {
    if (data) {
      setPlugchatConnected(data?.seller?.plugchatConnected)
      form.setFieldsValue({
        ...data.seller,
      });
    }
  }, [data, form]);

  const handleUpdate = async (values) => {
    try {
      let response;
      if (data?.seller) {
        response = await updateSeller({
          variables: { id: data.seller.id, attributes: values }
        });
      } 
      const errors = response.data?.createSeller?.errors || response.data?.updateSeller?.errors;
      if (errors && errors.length > 0) {
        message.error(errors.join(', '));
      } else {
        message.success('Vendedor atualizado com sucesso');
        loadSeller(); // Refetch the seller to update the form
      }
    } catch (error) {
      message.error('Falha ao atualizar o vendedor');
      console.error(error);
    }
  };

  if (queryLoading) return <p>Carregando...</p>;

  return (
    <Row justify="center" align="top" style={{ height: '100%' }}>
      <Col span={24}>
        <Card>
          <h2>Dados do Vendedor</h2>
          <Form
            form={form}
            initialValues={data?.seller}
            onFinish={handleUpdate}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor insira o nome' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Por favor insira o email' }]}>
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="whatsapp" label="Whatsapp" >
                  <Input disabled={plugchatConnected} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Col>
                <Button type="primary" htmlType="submit" >
                  Atualizar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SellerSettings;
