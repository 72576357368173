import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_CITIES } from '../mutations/address'; // Certifique-se de criar esta consulta em um arquivo apropriado
import useDebounce from '../hooks/useDebounce'; // Importar o hook de debounce

const { Option } = Select;

const AddressForm = ({ initialValues = {} }) => {
  const [searchCities, { data: citiesData }] = useLazyQuery(SEARCH_CITIES);
  const [cities, setCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      searchCities({ variables: { query: debouncedSearchTerm } });
    }
  }, [debouncedSearchTerm, searchCities]);

  useEffect(() => {
    if (citiesData) {
      setCities(citiesData.searchCities);
    }
  }, [citiesData]);

  useEffect(() => {
    if (initialValues.city) {
      setCities([{ id: initialValues.city.id, name: initialValues.city.name, state: initialValues.city.state }]);
    }
  }, [initialValues]);

  const handleSearchCity = (value) => {
    setSearchTerm(value);
  };
  const initialCityValue = initialValues.city
    ? { value: initialValues.city.id, label: `${initialValues.city.name} - ${initialValues.city.state.initials}` }
    : undefined;

  return (
    <>
      <Row gutter={16}>
        <Col span={3}>
          <Form.Item name={["address", "zipcode"]} label="CEP" rules={[{ required: true, message: 'Por favor, insira o CEP' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={["address", "cityId"]} label="Cidade" rules={[{ required: true, message: 'Por favor, selecione a cidade' }]}>
            <Select
              showSearch
              placeholder="Pesquisar cidade"
              onSearch={handleSearchCity}
              filterOption={false}
              value={initialCityValue}

              options={cities.map(city => ({ value: city.id, label: `${city.name} - ${city?.state?.initials}` }))}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={["address", "description"]} label="Descrição">
            <Input />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={["address", "district"]} label="Bairro" rules={[{ required: true, message: 'Por favor, insira o bairro' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={["address", "street"]} label="Rua" rules={[{ required: true, message: 'Por favor, insira a rua' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name={["address", "number"]} label="Número" rules={[{ required: true, message: 'Por favor, insira o número' }]}>
            <Input />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={["address", "complement"]} label="Complemento">
            <Input.TextArea allowClear={true} />
          </Form.Item>
        </Col>
      </Row>

    </>
  );
};

export default AddressForm;