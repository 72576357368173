// src/apolloClient.js
import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

// Optional: Import the shared history object if you're using React Router
// import history from './history';

const apiUrl = process.env.REACT_APP_API_URL;

const uploadLink = createUploadLink({
  uri: `${apiUrl}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Handle GraphQL errors
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      // Check if the error code indicates an authentication error
      if (extensions && extensions.code === 'UNAUTHENTICATED') {
        // Clear stored authentication tokens
        localStorage.removeItem('access-token');
        localStorage.removeItem('client');
        localStorage.removeItem('uid');

        // Redirect to the login page
        window.location.href = '/login';
        // If using React Router with a shared history object:
        // history.push('/login');
      }
    });
  }

  // Handle network errors
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);

    // Check if the network error status code is 401 (Unauthorized)
    if (networkError.statusCode === 401) {
      // Clear stored authentication tokens
      localStorage.removeItem('access-token');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');

      // Redirect to the login page
      window.location.href = '/login';
      // If using React Router with a shared history object:
      // history.push('/login');
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return {
    headers: {
      ...headers,
      'access-token': token || '',
      client: client || '',
      uid: uid || '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(uploadLink),
  cache: new InMemoryCache(),
});

export default client;
