import { gql, useMutation } from '@apollo/client';
// Query GraphQL para buscar clientes

export const UPDATE_CUSTOMER_BOT_STATUS = gql`
  mutation UpdateCustomerBotStatus($id: ID!, $botStatus: String!, $disableDuration: String) {
    updateCustomerBotStatus(input: { id: $id, botStatus: $botStatus, disableDuration: $disableDuration }) {
      customer {
        id
        botStatus
        botDisabledUntil
        timeUntilReactivation
      }
      errors
    }
  }
`;

export const useUpdateCustomerBotStatus = () => {
  return useMutation(UPDATE_CUSTOMER_BOT_STATUS);
};

export const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($id: ID!) {
    customer(id: $id) {
      id
      name
      nickname
      createdAt
      document
      stateRegistration
      phone
      whatsapp
      minimumOrder
      credit
      reference
      observation
      phoneExtra
      botStatus
      botDisabledUntil
      timeUntilReactivation
      active
      addresses {
        id
        number
        street
        description
        room
        zipcode
        district
        complement
        latitude
        main
        longitude
        cityId
        active
        city{
          id
          name
          state{
            id
            name
            initials
          }
        }
      }
      emails {
        id
        email
        description
        active
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($page: Int, $perPage: Int, $search: String) {
    customers(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          nickname
          createdAt
          document
          stateRegistration
          phone
          whatsapp
          minimumOrder
          credit
          reference
          observation
          phoneExtra
          active
          botStatus
          botDisabledUntil
          timeUntilReactivation
          addresses {
            id
            fullAddress
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      },
      totalCount

    }
  }
`;

const UPDATE_CUSTOMER_STATUS = gql`
  mutation UpdateCustomerStatus($id: ID!, $active: Boolean!) {
    updateCustomerStatus(input: { id: $id, active: $active }) {
      customer {
        id
        active
      }
      errors
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($attributes: CustomerAttributes!) {
    createCustomer(input: { attributes: $attributes }) {
      customer {
        id
          name
          nickname
          createdAt
          document
          stateRegistration
          phone
          whatsapp
          minimumOrder
          credit
          reference
          observation
          phoneExtra
          active
          botStatus
          botDisabledUntil
          timeUntilReactivation
          addresses {
            main
            id
            fullAddress
          }
        }
      errors
    }
  }
`;

export const useUpdateCustomerStatus = () => {
  return useMutation(UPDATE_CUSTOMER_STATUS);
};

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: ID!, $attributes: CustomerAttributes!) {
    updateCustomer(input: { id: $id, attributes: $attributes }) {
      customer {
        id
        name
        nickname
        createdAt
        document
        stateRegistration
        phone
        whatsapp
        minimumOrder
        credit
        reference
        observation
        botStatus
        botDisabledUntil
        timeUntilReactivation
        phoneExtra
        active
        addresses {
          main
          id
          fullAddress
        }
      }
      errors
    }
  }
`;

export const useUpdateCustomer = () => {
  return useMutation(UPDATE_CUSTOMER);
};

export const useCreateCustomer = () => {
  return useMutation(CREATE_CUSTOMER);
};