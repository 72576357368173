import { useQuery } from '@apollo/client';
import { Card, Row, Col } from 'antd';
import { SALES_SUMMARY } from '../mutations/dashboard';
import SalesChart from '../components/SalesChart';
import { formatCurrency } from '../util';
import OrderStatusChart from '../components/OrderStatusChart';
import TopSellingProductsChart from '../components/TopSellingProductsChart';
import TopSellingProductsByMonthChart from '../components/TopSellingProductsByMonthChart';


const Dashboard = () => {
  const { loading, error, data } = useQuery(SALES_SUMMARY, { fetchPolicy: 'network-only' });
  const currentMonth = new Date().getMonth() + 1; // Janeiro é 0
  const currentYear = new Date().getFullYear();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Total faturado no mês" bordered={false} >
            {formatCurrency(data.salesSummary.totalSalesMonth)}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total vendido no mês" bordered={false} >
            {formatCurrency(data.salesSummary.totalOnMonth)}
          </Card>
        </Col>

        <Col span={8}>

          {/* <SalesChart data={data.salesSummary.salesByCategory} /> */}
        </Col>
        <Col span={8}>
          {/* <SalesChart data={data.salesSummary.salesBySeller} /> */}
        </Col>
      </Row>
      <br></br>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Quantidade de pedidos no mês por status">
            <OrderStatusChart month={currentMonth} year={currentYear} />
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Produtos Mais Vendidos no Mês (faturados">
            <TopSellingProductsByMonthChart month={currentMonth} year={currentYear} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Produtos Mais Vendidos (faturados)">
            <TopSellingProductsChart />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
