import { gql, useMutation } from '@apollo/client';

export const SEARCH_PAYMENT_CONDITIONS = gql`
  query SearchPaymentConditions($query: String!) {
    searchPaymentConditions(query: $query) {
      id
      name
    }
  }
`;

export const GET_PAYMENT_CONDITIONS = gql`
  query GetPaymentConditions($page: Int, $perPage: Int, $search: String) {
    paymentConditions(page: $page, perPage: $perPage, search: $search) {
      edges {
        node {
          id
          name
          installments
          active
          inCash
          createdAt
          updatedAt
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const CREATE_PAYMENT_CONDITION = gql`
  mutation CreatePaymentCondition($attributes: PaymentConditionAttributes!) {
    createPaymentCondition(input: { attributes: $attributes }) {
      paymentCondition {
        id
        name
        installments
        active
        inCash
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_PAYMENT_CONDITION = gql`
  mutation UpdatePaymentCondition($id: ID!, $attributes: PaymentConditionAttributes!) {
    updatePaymentCondition(input: { id: $id, attributes: $attributes }) {
      paymentCondition {
        id
        name
        installments
        active
        inCash
        createdAt
        updatedAt
      }
      errors
    }
  }
`;

export const UPDATE_PAYMENT_CONDITION_STATUS = gql`
  mutation UpdatePaymentConditionStatus($id: ID!, $active: Boolean!) {
    updatePaymentConditionStatus(input: { id: $id, active: $active }) {
      paymentCondition {
        id
        active
        updatedAt
      }
      errors
    }
  }
`;

export const useCreatePaymentCondition = () => {
  return useMutation(CREATE_PAYMENT_CONDITION);
};

export const useUpdatePaymentCondition = () => {
  return useMutation(UPDATE_PAYMENT_CONDITION);
};

export const useUpdatePaymentConditionStatus = () => {
  return useMutation(UPDATE_PAYMENT_CONDITION_STATUS);
};
