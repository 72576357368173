// src/pages/Products.js
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Switch, Modal } from 'antd';
import { format } from 'date-fns';
import { useUpdateProductStatus, GET_PRODUCTS, GET_PRODUCT_BY_ID } from '../mutations/products';
import ProductModalForm from '../components/products/ProductModalForm';
import ProductImportModal from '../components/ProductImportModal';

const Products = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [updateProductStatus] = useUpdateProductStatus();

  const [fetchProducts, { loading, error, data }] = useLazyQuery(GET_PRODUCTS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [getProductById, { loading: loadingProduct, data: productData }] = useLazyQuery(GET_PRODUCT_BY_ID);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1); // Reset page to 1 when search term changes
    fetchProducts({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const showConfirmModal = (product) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleImport = () => {
    setIsImportModalVisible(true);
  };

  const handleImportCancel = () => {
    setIsImportModalVisible(false);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedProduct;
    try {
      await updateProductStatus({
        variables: { id, active: !active },
        update: (cache, { data: { updateProductStatus } }) => {
          const { product } = updateProductStatus;
          cache.modify({
            id: cache.identify(product),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setIsFormModalVisible(false);
    setIsModalVisible(false);
  };

  const resetForm = () => {
    setSelectedProduct(null);
    setIsEditMode(false);
  };

  const handleCreateProduct = () => {
    resetForm();
    setIsFormModalVisible(true);
  };

  const handleEditProduct = (product) => {
    setIsEditMode(true);
    getProductById({ variables: { id: product.id } });
    setIsFormModalVisible(true);
  };

  useEffect(() => {
    fetchProducts(); // Fetch products when component mounts
  }, [fetchProducts]);

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Código',
      dataIndex: 'reference',
      key: 'reference',
      align: 'center',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Grupo',
      dataIndex: ['group', 'name'],
      key: 'group',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditProduct(record)}>Editar</Button>
      ),
    },
  ];

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchProducts({
      variables: {
        page: page,
        perPage: pageSize,
        search: searchQuery,
      },
    });
  };

  const products = data?.products?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.products || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateProduct}>
          Criar Produto
        </Button>
        <Button type="primary" style={{ marginLeft: '5px' }} onClick={handleImport}>
          Importar Produtos
        </Button>
      </div>
      <Table
        dataSource={products}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={handleTableChange}
      />
      <Modal
        title="Confirmação"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        getContainer={false}
      >
        <p>Tem certeza que deseja continuar?</p>
      </Modal>
      <ProductModalForm
        visible={isFormModalVisible}
        onCancel={handleCancel}
        initialValues={isEditMode && productData ? productData.product : {}}
        isEditMode={isEditMode}
        refetchProducts={fetchProducts}
        key={isEditMode ? selectedProduct?.id : 'new'} // Add key to force re-render
      />
      <ProductImportModal
        visible={isImportModalVisible}
        onCancel={handleImportCancel}
        refetchProducts={fetchProducts}
      />
    </>
  );
};

export default Products;
