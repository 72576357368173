import React, { useState } from 'react';
import { Modal, Select, Row, Col } from 'antd';

const { Option } = Select;

const UpdateStatusBotModal = ({ visible, onCancel, onUpdate, botStatus }) => {
  const [newBotStatus, setNewBotStatus] = useState(botStatus);
  const [disableDuration, setDisableDuration] = useState(null);

  const handleStatusChange = (value) => {
    setNewBotStatus(value);
  };

  const handleDisableDurationChange = (value) => {
    setDisableDuration(value);
  };

  const handleOk = () => {
    onUpdate(newBotStatus, disableDuration);
  };

  return (
    <Modal
      title="Atualização de Status do Bot"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      maskClosable={false}
    >
      <Row gutter={16}>
        <Col span={12}>
          <label>Ação</label>
          <Select
            value={newBotStatus}
            onChange={handleStatusChange}
            style={{ width: '100%' }}
          >
            <Option value="bot_active">Ativar</Option>
            <Option value="bot_disabled">Desativar</Option>
          </Select>
        </Col>
        {newBotStatus === 'bot_disabled' && (
          <Col span={12}>
            <label>Duração</label>
            <Select
              onChange={handleDisableDurationChange}
              style={{ width: '100%' }}
            >
              <Option value="3600">Por 1 hora</Option>
              <Option value="28800">Por 8 horas</Option>
              <Option value="86400">Por 24 horas</Option>
              <Option value="forever">Para sempre</Option>
            </Select>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default UpdateStatusBotModal;
