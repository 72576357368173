import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Switch, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CREATE_PAYMENT_PROFILE, GET_PAYMENT_PROFILES, UPDATE_PAYMENT_PROFILE, UPDATE_PAYMENT_PROFILE_STATUS } from '../mutations/paymentProfiles';
import PaymentProfileForm from '../components/paymentProfiles/PaymentProfileForm';
import { format } from 'date-fns';
const { confirm } = Modal;

const PaymentProfiles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasActiveProfile, setHasActiveProfile] = useState(false);


  const [updatePaymentProfile] = useMutation(UPDATE_PAYMENT_PROFILE, {
    update: (cache, { data: { updatePaymentProfile } }) => {
      if (updatePaymentProfile?.paymentProfile) {
        const { paymentProfile } = updatePaymentProfile;
        cache.modify({
          fields: {
            paymentProfiles(existingPaymentProfiles = {}) {
              const updatedPaymentProfiles = existingPaymentProfiles.edges.map(edge => {
                if (edge.node.id === paymentProfile.id) {
                  return { node: paymentProfile };
                }
                return edge;
              });
              return {
                ...existingPaymentProfiles,
                edges: updatedPaymentProfiles,
              };
            },
          },
        });
      }
    },
  });
  
  const [createPaymentProfile] = useMutation(CREATE_PAYMENT_PROFILE, {
    update: (cache, { data: { createPaymentProfile } }) => {
      if (createPaymentProfile?.paymentProfile) {
        const { paymentProfile } = createPaymentProfile;
        cache.modify({
          fields: {
            paymentProfiles(existingPaymentProfiles = {}) {
              const newPaymentProfileRef = cache.writeFragment({
                data: paymentProfile,
                fragment: gql`
                  fragment NewPaymentProfile on PaymentProfile {
                    id
                    name
                    createdAt
                    updatedAt
                    profileMethodConditions {
                      paymentMethodId
                      paymentConditionId
                      paymentMethod {
                        id
                        name
                      }
                      paymentCondition {
                        id
                        name
                      }
                    }
                    active
                  }
                `
              });
              return {
                ...existingPaymentProfiles,
                edges: [...existingPaymentProfiles.edges, { node: newPaymentProfileRef }]
              };
            }
          }
        });
      }
    }
  });

  const handleFinish = async (values) => {
    try {
      const profileMethodConditions = values.profileMethodConditions.map(condition => ({
        id: condition.id,
        paymentMethodId: condition.paymentMethodId,
        paymentConditionId: condition.paymentConditionId,
        minimumValue: condition.minimumValue
      }));

    

      const mutation = isEditMode ? updatePaymentProfile : createPaymentProfile;
      const variables = isEditMode
        ? { id: selectedProfile.id, name: values.name, active: values.active, profileMethodConditions: profileMethodConditions }
        : { name: values.name, active: values.active, profileMethodConditions: profileMethodConditions };

      const { data } = await mutation({ variables });

      if ((data.createPaymentProfile || data.updatePaymentProfile).errors.length > 0) {
        setErrorMessage((data.createPaymentProfile || data.updatePaymentProfile).errors.join(', '));
      } else {
        handleFormCancel();
        fetchProfiles();
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const showConfirm = (id, active) => {
    confirm({
      title: `Você tem certeza que deseja ${active ? 'ativar' : 'desativar'} este perfil de pagamento?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleToggleActive(id, active);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  const [updatePaymentProfileStatus] = useMutation(UPDATE_PAYMENT_PROFILE_STATUS);
  const handleToggleActive = async (id, active) => {
    try {
      await updatePaymentProfileStatus({ variables: { id, active } });
      fetchProfiles();
    } catch (error) {
      console.error('Error updating payment profile status:', error);
    }
  };

  const [fetchProfiles, { loading, error, data }] = useLazyQuery(GET_PAYMENT_PROFILES, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchProfiles({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreateProfile = () => {
    setSelectedProfile(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditProfile = (profile) => {
    const initialValues = {
      ...profile,
      profileMethodConditions: profile.profileMethodConditions.map(condition => ({
        id: condition.id,
        paymentMethod: condition.paymentMethod,
        paymentMethodId: condition.paymentMethod.id,
        paymentCondition: condition.paymentCondition,
        paymentConditionId: condition.paymentCondition.id,
        minimumValue: condition.minimumValue
      }))
    };
    setSelectedProfile(initialValues);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedProfile(null);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (data) {
      const profiles = data.paymentProfiles.edges.map(edge => edge.node);
      setHasActiveProfile(profiles.some(profile => profile.active));
    }
  }, [data]);

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirm(record.id, !active)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditProfile(record)}>Editar</Button>
      ),
    },
  ];

  const profiles = data?.paymentProfiles?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.paymentProfiles || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreateProfile} disabled={hasActiveProfile}>
          Novo Perfil de Pagamento
        </Button>
      </div>
      <Table
        dataSource={profiles}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchProfiles({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Perfil de Pagamento" : "Criar Perfil de Pagamento"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
        width={1200}
      >
        <PaymentProfileForm
          initialValues={selectedProfile}
          isEditMode={isEditMode}
          onFinish={handleFinish}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
    </>
  );
};

export default PaymentProfiles;
