import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query GetOrders($page: Int, $perPage: Int, $search: String, $statuses: [String!], $startDate: String, $endDate: String) {
    orders(page: $page, perPage: $perPage, search: $search, statuses: $statuses, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          status
          code
          createdAt
          totalProducts
          total
          addition
          discount
          situation
          billingDate
          nfNumber
          customer {
            name
            phone
            whatsapp
            addresses {
              id
              fullAddress
            }
          }
          orderProducts {
            id
            quantity
            discountPercent
            additionPercent
            salesValue
            value
            total
            barcode
            product {
              id
              name
              reference
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($id: ID!, $status: String!, $situation: String, $billingDate: String, $nfNumber: String) {
    updateOrderStatus(input: {id: $id, status: $status, situation: $situation, billingDate: $billingDate, nfNumber: $nfNumber}) {
      order {
        id
        status
        situation
        billingDate
        nfNumber
      }
    }
  }
`;
export const EXPORT_ORDERS = gql`
  query ExportOrders($format: String!, $statuses: [String!], $startDate: String, $endDate: String) {
    exportOrders(format: $format, statuses: $statuses, startDate: $startDate, endDate: $endDate)
  }
`;