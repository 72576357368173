import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AppLayout from './components/Layout';
import Orders from './pages/Orders';
import Products from './pages/Products';
import Customers from './pages/Customers';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm'; // Importar o RegisterForm
import AccountSettings from './components/AccountSettings';
import ConfirmEmail from './components/ConfirmEmail';
import SendConfirmationEmail from './components/SendConfirmationEmail';
import ResetPassword from './components/ResetPassword';
import RequestPasswordReset from './components/RequestPasswordReset';
import Groups from './pages/Groups';
import PaymentMethods from './pages/PaymentMethods';
import PaymentConditions from './pages/PaymentConditions';
import SellerSettings from './components/SellerSettings';
import PaymentProfiles from './pages/PaymentProfiles';
import Dashboard from './pages/Dashboard';


// Componente para proteger rotas
const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('access-token');
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/resend-confirmation-email" element={<SendConfirmationEmail/>} />
        <Route path="/confirm" element={<ConfirmEmail/>} />
        
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} /> 
        <Route path="/request-password-reset" element={<RequestPasswordReset />} /> {/* Adicionar a rota para solicitar redefinição de senha */}
        <Route path="/reset_password" element={<ResetPassword />} /> {/* Adicionar a rota para redefinir senha */}
        <Route path="/" element={<PrivateRoute element={AppLayout} />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="seller-settings" element={<SellerSettings />} />
          <Route path="account-settings" element={<AccountSettings />} /> {/* Adicionar a rota de configuração de conta */}
          <Route path="orders" element={<Orders />} />
          <Route path="products" element={<Products />} />
          <Route path="/paymentMethods" element={<PaymentMethods/>} />
          <Route path="/paymentConditions" element={<PaymentConditions/>} />
          <Route path="customers" element={<Customers />} />
          <Route path="paymentProfiles" element={<PaymentProfiles/>} />
          <Route path="groups" element={<Groups />} /> {/* Adicionar a rota para listagem de grupos */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;